import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import cors from 'cors'

// import { brandSet } from '@coreui/icons'
// import * as icon from '@coreui/icons';
// icons.add(icon)

import DocsExample from '@/components/DocsExample'

const app = createApp(App)
app.use(cors)
// import { initializeApp } from "firebase/app"
// import { getAuth } from "firebase/auth"
// const auth = getAuth()
// import { getDatabase } from "firebase/database";

// initializeApp({
//   apiKey: "AIzaSyDggJ7mxXrkjVjqgmktG1uhkejW2hjbhSI",
//   authDomain: "api-project-67582764779.firebaseapp.com",
//   projectId: "api-project-67582764779",
//   storageBucket: "api-project-67582764779.appspot.com",
//   messagingSenderId: "67582764779",
//   appId: "1:67582764779:web:f8f7c3c713ea247b23c0ae",
//   measurementId: "G-DWN6LLLVK7"
// })
// console.log(auth)
// getAuth(()=>{
//   if (auth) {
//     console.log('Login')
//   }
// })

// const cors = require('cors')
// app.use(cors())

app.use(store)
app.use(router)
app.use(CoreuiVue)
// app.provide('icons', brandSet) //показывает наоборот брендовые иконки с icons если местами поменять
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.use(Vuelidate)

app.mount('#app')
